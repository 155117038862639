.message-list {
  list-style: none; /* Remove list dot */
  display: flex; /* Set ul to flex container */
  flex-direction: column; /* Set flex direction to column */
  gap: 0.5rem;
  padding-left: 0;
}

.message-list li {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.message-time {
  font-size: small;
}

.main-contact {
  position: fixed;
  bottom: 0.2rem;
  right: 1rem;
  z-index: 1000;
  overflow: visible;
  z-index: 10000;
}
